<template>
  <!-- 
  TODO: Not found

https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2018meeting/022-HeatedDropWeightCapability%E2%80%93LisaKay.pdf
https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2018meeting/031-IntendedUseandHazardClassificationTesting%E2%80%93ErikWrobel.pdf



 -->
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2018 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Aerospace Corporation</li>
              <li>BAE Systems</li>
              <li>
                BATF / National Center for Explosives Training &amp; Research
              </li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>Department of Homeland Security</li>
              <li>Department of Justice</li>
              <li>Esterline Defense Technologies</li>
              <li>Energetic Materials Research and Testing Center</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>
                Federal Institute for Materials Research and Testing (BAM)
              </li>
              <li>Lawrence Livermore National Laboratory</li>
              <li>Los Alamos National Laboratory</li>
              <li>Naval Research Laboratory</li>
              <li>Naval Surface Warfare Center</li>
              <li>
                Orbital ATK
                <ul class="list-disc p-light ml-8">
                  <li>ABL</li>
                  <li>Corporate</li>
                  <li>Bacchus</li>
                  <li>Promontory</li>
                  <li>Lake City</li>
                </ul>
              </li>
              <li>Safety Management Services, Inc.</li>
              <li>Tooele Army Depot</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (DG
                Advisor, LLC)
              </li>
              <li>Sandia National Laboratory</li>
              <li>Signature Science, LLC</li>
              <li>U.S. Army RDECOM – ARDEC – Picatinny Arsenal</li>
              <li>Vista Outdoor – CCI/Speer</li>
              <li>Vista Outdoor – Federal Premium Ammunition</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2018 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F001-Welcome-BobFord.pdf?alt=media&token=81f349fe-4553-45ef-b2cc-3c060dd8e758"
                        >001 - Welcome - Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F002-StandardizationandETUGCharter-BobFord.pdf?alt=media&token=1801f7e0-0052-47cf-a2ae-ba0328bcfdb2"
                        >002 - Standardization and the ET Users Group Charter -
                        Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F003-StandardizationVerificationOverviewandStatus-ClintGuymon.pdf?alt=media&token=84bbf4a2-f58c-403f-aca5-1b5036280c36"
                        >003 - Standardization Verification Overview and Status
                        - Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F004-TNOBAMFrictiontestRoundRobin.pdf?alt=media&token=98851cfe-4103-4a03-b40d-d00ad8410660"
                        >004 - BAM Friction Test Round Robin - Ed de Jong</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F005-ETUGProposedStandardsforCertification-ETUsersGroup.pdf?alt=media&token=869fbe0c-13f0-44d9-8cdb-4a64b41e5da0"
                        >005 - ETUG Proposed Standards for In-Process
                        Sensitivity Testing Qualification - Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F005a-ETUGCertificationofLaboratoriesDiscussion-BobFord.pdf?alt=media&token=2962ad0c-9a90-4d00-932e-86a4bc0dd335"
                        >005a - ETUG Certification of Laboratories - Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F006-ReviewofDSCRoundRobinObjectivesandApproach.pdf?alt=media&token=e21867e3-e20d-4ad2-8a26-ee2af4f6d6a8"
                        >006 - Review of DSC Round Robin Objectives and Approach
                        - Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F007-SMSDSCLaboratoryTestResults.pdf?alt=media&token=c71bfa25-1fac-4237-9a78-063445981234"
                        >007 - SMS DSC Laboratory Test Results - Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F008-2018ETUGDSCRoundRobin-JasonPhillips.pdf?alt=media&token=498a481a-9a62-421d-a990-12ae98b2bae8"
                        >008 - ETUG DSC Round Robin Results- Sandia - Jason
                        Phillips</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F009-TNO-DSCRoundRobin-EddeJong.pdf?alt=media&token=bfe8e9eb-0d6d-4c57-b86c-085d9aaa18d7"
                        >009 - ETUG DSC Round-Robin- TNO - Ed de Jong</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F010-LANL-DSCRoundRobin-HongzhaoTian.pdf?alt=media&token=bd60c12d-f5ad-4c97-9743-f93c94a90406"
                        >010 - DSC Round Robin Testing LANL Results - Hongzhao
                        Tian</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F011-OklahomaStateUniversity-DSCRoundRobin-MattGreen.pdf?alt=media&token=4ad04153-eee2-4d86-8cd0-43866f80eda2"
                        >011 - DSC Round- Robin Oklahoma State University
                        Results - Matthew Green</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F012-DSCETUGRoundRobin2018PUB001626.pdf?alt=media&token=a0715b25-8016-4a85-97d6-bc4c1e60f5ea"
                        >012 - Northrop Grumman Promontory-DSC ETUG Round Robin
                        2018 - Geoffry Schrank</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F013-NorthropGrumman-ABL-DSCRoundRobin-Geoff%20Frech.pdf?alt=media&token=22c47e3b-03dc-4957-b997-a8b25d3996e6"
                        >013 - ETUG DSC Round Robin Results, ABL Chemistry Lab -
                        Geoffrey Frech</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F014-CanadianExplosivesResearchLaboraatory-DSCRoundRobin-SandraGoldthrop.pdf?alt=media&token=248da6e7-99eb-4db5-aad7-0f2f8fe4b678"
                        >014 - CERL Test Results for DSC Round Robin - Sandra
                        Goldthorp</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F015-ETUGDSCSummaryofResults.pdf?alt=media&token=2f36d8c2-d7ce-45a1-a33b-2df8f2af4373"
                        >015 - ETUG DSC Summary of Results - Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F016-SBATOverviewandRoundRobinTesting-JasonFord.pdf?alt=media&token=9428db9a-8bd5-4f21-a23d-f9e5ad8af2d9"
                        >016 - SBAT Overview and Round Robin Testing - Jason
                        Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F017-SpecialShippingContainer-jasonford.pdf?alt=media&token=917348e0-ab10-4aac-82eb-62929c8de72b"
                        >017 - Round Robin Sample Shipping Using Specialized
                        Container - Jason Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F018-EnergyCalculationandEquivalence-TroyGardner%2CSMSandJohnReynolds%2CLLNL.pdf?alt=media&token=0414ecee-0e23-4f1e-87e9-34b0c72dd747"
                        >018 - Energy Calculation and Equivalence for the DOT-SP
                        8451 Shipping Container - John Reynolds and Troy
                        Gardner</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F019-ETUGspecialpermit(Kirt).pdf?alt=media&token=0aeca4ef-6878-48f8-bb0c-516554600d09"
                        >019 - International Initiative for a Specialized
                        Container - Status on Dedicated UN Number - Ben Barrett
                        and Kirt Sasser</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F020-10thMeetingHistorySlide.pdf?alt=media&token=5dd3acf1-29cd-40a1-850e-69635af79638"
                        >020 - 10th Meeting Anniversary of the ET Users Group -
                        Boyd Hill</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F021-BAMFrictionTesterComparisonStudy.pdf?alt=media&token=03e62f03-0454-40e7-8dc2-39a7b1175980"
                        >021 - A Comparison Study of Two BAM Friction Tester
                        Designs - Jason Phillips</a
                      >
                      <!--                       <span class="mb-2" style="color:grey;"
                        >022 - Development of Heated Drop Weight Impact
                        Capabilities at LANL - Lisa Kay</span
                      > -->
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F023-BrownFinalETUGBayes2018LA-UR-18-29308.pdf?alt=media&token=210312e6-ee38-455b-acd4-04d3a6bf579e"
                        >023 - Directed Bayesian Estimation of Explosive
                        Sensitivity - Geoff Brown</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F024-GasAnalysisofSSSTETUG-BenOstrow.pdf?alt=media&token=b25aac4f-7c0c-4992-85df-b58d201e59cd"
                        >024 - Results of Gas Monitoring of Small-Scale Safety
                        Testing - Benjamin Ostrow</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F025-ReactionDeterminationwithNon-VisibleLightDetection-JaredTeter.pdf?alt=media&token=42d06ab7-1962-4411-99a2-c148b28673e5"
                        >025 - Sensitivity Testing Reaction Detection Using an
                        Optical Spectrometer - Update - Jared Teter</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F027-RecapofGoDetectUseandUpdates-RustyChristensen.pdf?alt=media&token=3dfe2a66-7a49-462e-9de7-8f25ce313819"
                        >027 - Recap of GoDetect-ESD Use and Updates - Rusty
                        Christensen</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F028-NASAWallopsFlightFacilityIntroductionandChallenges.pdf?alt=media&token=4e650960-c1ab-4d72-aeba-97d1f291c103"
                        >028 - Wallops Flight Facility Introduction &amp;
                        Challenges - Shad Combs</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F029-ETUGIn-ProcessClassificationStandard(ETUG-GS01-15)(Kirt).pdf?alt=media&token=5dad93eb-0d4f-47ff-bdf0-54c83fc39ac9"
                        >029 - In-Process Hazards Classification (Standard:
                        ETUG-GS01-15) - Kirt Sasser</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F030-ETUGMeeting2018PRES759519(947847)RS-JohnReynolds.pdf?alt=media&token=bb1a1e80-f4e6-4e5b-b56e-fce5c5a251bc"
                        >030 - Safety Testing and Characterization of Thermally
                        Treated LX-17 - John Reynolds</a
                      >
                      <!--                       <span class="mb-2" style="color:grey;"
                        >031 - "Intended Use" and Hazard Classification Testing
                        - Erik Wrobel</span
                      > -->
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F032-IntegratedViolenceModeland1.3Events-ClintGuymon.pdf?alt=media&token=317db760-638a-46f1-ae5d-8b18091bec33"
                        >032 - Integrated Violence Model (IVM) and 1.3 Events -
                        Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F033-ETUGMeetingOct2018-JoCovino.pdf?alt=media&token=9274ce9c-02a5-443a-a371-2885ea3b98c0"
                        >033 - Combustion Behavior and Quantity Distance (QD)
                        Siting - Dr Josephine Covino</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F034-UnchamberedCartridgeBulletEnergies-TroyGardner%2CSMS%2CClintGuymon%2CSMS%2CandBenBarrett%2CSAAMI.pdf?alt=media&token=8241304c-d90c-4185-a844-5aad9a65a550"
                        >034 - Unchambered Cartridge Bullet Energies - Troy
                        Gardner, Clint Guymon, and Ben Barrett</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F035-2018ETUG-HistoryofThermalMeasurementTheoryTests-DTADSCSBAT.pdf?alt=media&token=9521c06f-5b2b-4bc8-afc2-29d49a0b0038"
                        >035 - History of Thermal Measurement, Theory &amp;
                        Tests - Boyd Hill</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F036-CriticalHeightTestingETUGOct102018.pdf?alt=media&token=205f9fae-cd41-44fb-983a-ada812f8d625"
                        >036 - Critical Height Testing - Chucky Williams</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F037-Small-scaleCook-offandDDTFriability(MalcolmCookAWE).pdf?alt=media&token=6711c1c4-6e43-4d62-b6f8-c86cad697e8a"
                        >037 - Small-Scale Cook-off/DDT Friability - Malcolm
                        Cook</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F038-ThermitesandHazardsClassification.pdf?alt=media&token=ec93a415-3e89-47b0-907e-937c0e466e86"
                        >038 - Thermites and Hazards Classification - Troy
                        Gardner</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
